import React from "react";
import { StaticQuery, graphql } from "gatsby";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
const HowToDiagram = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allHowToJson {
            nodes {
              image {
                publicURL
              }
              altText
              name
              description
            }
          }
        }
      `}
      render={(data) => (
        <Box
          sx={{
            py: 1,
            display: { xs: "flex", sm: "flex" },
          }}
        >
          <Grid container rowSpacing={2} spacing={2}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
                When to use a message
              </Typography>
            </Grid>
            {data.allHowToJson.nodes.map((item, index) => {
              return (
                <>
                  <Grid item xs={12} sm={6} md={3} key={`howTo_${index}`}>
                    <Paper sx={{height:"100%"}}>
                      <Container
                        sx={{ display: { xs: "flex"}, bgcolor: "secondary.main" }}
                      >
                        <Box
                          component="img"
                          src={item.image.publicURL}
                          alt={item.altText}
                          alignItems="center"
                          justifyContent="center"
                          sx={{ bgcolor: "secondary.main", m:"auto" }}
                        />
                      </Container>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ pt: 2, px: 2 }}
                      >
                        {item.name}
                      </Typography>
                      <Divider />
                      <Typography variant="body2" sx={{ py: 2, px: 2 }} dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Paper>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      )}
    />
  );
};

export default HowToDiagram;
