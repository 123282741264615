import React from "react";
import Layout from "../components/layout";
import FeaturedGrid from "../components/featuredGrid";
import HowToDiagram from "../components/howToDiagram";
import WorksWith from "../components/worksWith";

const IndexPage = () => {
  return (
    <>
      <Layout title="Home" url="/">
        <HowToDiagram />
        <FeaturedGrid viewMoreButton={true} />
        <WorksWith />
      </Layout>
    </>
  );
};

export default IndexPage;
