import React from "react";
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";
import { StaticQuery, graphql } from "gatsby";

const WorksWith = () => {
  return (
    <StaticQuery
      query={graphql`
        query queryWorksWith {
          allWorksWithJson(sort: { fields: label, order: ASC }) {
            edges {
              node {
                image {
                  publicURL
                }
                label
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
                Works With
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "none", md: "flex" },
                  backgroundColor: "secondary.main",
                  pt: 3,
                  px: 3,
                  mb: 3,
                }}
              >
                <ImageList gap={36} cols={4} variant="masonry">
                  {getImages(data)}
                </ImageList>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "flex", md: "none" },
                  backgroundColor: "secondary.main",
                  pt: 3,
                  px: 3,
                  mb: 3,
                }}
              >
                <ImageList gap={36} cols={2} variant="masonry">
                  {getImages(data)}
                </ImageList>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", sm: "none", md: "none" },
                  backgroundColor: "secondary.main",
                  pt: 3,
                  px: 3,
                  mb: 3,
                }}
              >
                <ImageList gap={36} cols={1} variant="masonry">
                  {getImages(data)}
                </ImageList>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

export default WorksWith;

function getImages(data) {
  const imageListArray = [];
  data.allWorksWithJson.edges.map((item) =>
    imageListArray.push(
      <Paper
        elevation={0}
        sx={{ mb: 0.5, mt: 0.5, backgroundColor: "secondary.main" }}
      >
        <ImageListItem>
          <img src={item.node.image.publicURL} alt={item.node.label} />
        </ImageListItem>
      </Paper>
    )
  );
  return imageListArray;
}
