import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import TitlebarBelowImageList from "../components/titlebarBelowImageList";

const FeaturedGrid = ({ viewMoreButton }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        <Grid container rowSpacing={2} spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              Browse{" "}
            </Typography>
            <TitlebarBelowImageList
              cols={2}
              maxResults={8}
              viewMoreButton={viewMoreButton}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <Grid container rowSpacing={2} spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
              Browse{" "}
            </Typography>
            <TitlebarBelowImageList
              cols={1}
              maxResults={8}
              viewMoreButton={viewMoreButton}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FeaturedGrid;

FeaturedGrid.defaultProps = {
  viewMoreButton: false,
};

FeaturedGrid.propTypes = {
  viewMoreButton: PropTypes.bool,
};
